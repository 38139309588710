import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { Language, LANGUAGE } from 'ui/constants/language';

const useMultiLanguage = () => {
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;
  const languageType = (searchParams.get('languageType') as Language) || LANGUAGE.KOR;
  const isKorean = languageType === LANGUAGE.KOR;

  const multiLanguageText = (kor: string, eng: string) => (isKorean ? kor : eng);

  return { multiLanguageText, languageType };
};

export default useMultiLanguage;
